import React from 'react';

const PremiumHeader = ({onClickFancy, sportsId}) => {
    return (
        <div id="sportsBookHead" className="sportsbook_bet-head">
            <h4 id="betHeader">
                <span id="sportsBookName" >
                    <pre id="inPlayTag" style={{ display: 'none' }}>in-play</pre>
                    Premium {' '}
                    {sportsId == 4 ? "Cricket" : sportsId == 2 ? "Tennis" : sportsId == 1 ? "Soccer" : ""}
                </span>
                <a id="sportsBookRules" className="btn-head_rules">Rules</a>
            </h4>
            {(sportsId == 4) && <a id="showFancyBetBtn" className="other-tab" onClick={onClickFancy}>Fancy Bet</a>}
            <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{ display: 'none' }}>Min/Max</a>
            <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{}}>Min</a>
            <div id="minMaxWrap_1" className="fancy_info-popup" style={{ display: 'none' }}>
                <dl>
                    <dt  >Min/Max</dt>
                    <dd id="minMaxInfo_1">0.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName_1">Rebate</dt>
                    <dd id="rebateRatio_1">0.00%</dd>
                </dl>
                {/*<a onClick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>*/}
            </div>
            <div id="minMaxWrap_2" className="fancy_info-popup" style={{ display: 'none' }}>
                <dl>
                    <dt>Min</dt>
                    <dd id="minMaxInfo_2">10.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName_2" style={{ display: 'none' }}>Rebate</dt>
                    <dd id="rebateRatio_2" style={{ display: 'none' }}>0.00%</dd>
                </dl>
                {/*<a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>*/}
            </div>
        </div>
    )
}

export default PremiumHeader;
