import React, { memo } from 'react';


const LoadingOverlay = ({ show, style }) => {
    if (show) {
        return (
            <div id="poker_loading" class="loading-overlay" style={{ display: 'flex' }}>
                <div class="loading-wrap">
                    <div class="loading">
                        <div></div>
                        <div></div>
                    </div>
                    <p>Loading...</p>
                </div>
            </div>
        );
    }
    return null;
};

export default memo(LoadingOverlay);
