import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import sportsicons from '../images/playicon/game-icon.png'
require('moment-timezone');

export default function SoccerMatches(props) {


  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [ScoreData, setScoreData] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);


  // vvvv

  useEffect(() => {

    Axios.get(
      `https://luckybdt.live/clientapi/soccer`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }
      })


      .catch(error => console.log(error));

    Axios.get(
      `https://luckybdt.live/clientapi/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://luckybdt.live/clientapi/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));


    Axios.get(
      `https://luckybdt.live/clientapi/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);

  // console.log('Soccer');
  return (

    <React.Fragment>

      {newrefresh === true &&
        <div class="loading-overlay" id="loading">
          <div class="loading-wrap" style={{ display: " flex" }}>
            <div class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}


      {newrefresh === false && < >
        <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/1.png?v=1" /></div>
        {match.map(function (item, id) {
          let customScore = '';
          let elapsTime = '';
          let filteredData = ScoreData &&
            ScoreData.filter(x => String(x.eventId) === String(item.eventId));
          // let filteredData = [];
          if (filteredData && filteredData.length > 0) {
            customScore = filteredData[0].score;
            elapsTime = filteredData[0].timeElapsed
          }
          var showtv = false;
          var isbookmaker = false;

          var showColor = false;

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (tv.includes(item.eventId)) {
            showtv = true;
          }
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          }

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }

          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showColor = true;
          }


          return (

            <li id="highlightEvent_32912573" style={{}} className={`event_list event_4 ${showColor ? "inplay-on" : "inplay-off"}`} >
              <Link to={`/exchange/play/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" href="/mobile/eventDetails/32912573?type=4">
                {showColor ? <img alt="" src={sportsicons} /> : <img alt="" src={sportsicons} />}
                {/* <img alt="" src={showColor ? { {sportsicons} } : {sportsoff}} /> */}
                <dl>
                  <dt>
                    {moment() >= moment(item.marketStartTime) && <span id="streamingIcon" className="game-live" >game-live</span>}
                    <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>
                    {/* {isFancy && <span id="fancyBetIcon" className="game-fancy" >Fancy</span>} */}
                    {isbookmaker && <span id="bookMakerIcon" className="game-bookmaker" style={{ display: 'flex' }}>BookMaker</span>}
                    <span id="feedingSiteIcon" className="game-sportsbook" style={{ display: 'none' }}>Sportsbook</span>
                    <span id="sportsBookIcon_1" className="game-sportsbook" style={{ display: 'none' }}>Premium Tennis</span>
                    <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: 'none' }}>Premium Cricket</span>
                    <span id="dateTimeInfo" className="time">{matchTime}</span>
                    <span id="sportsBookEIcon_1" className="game-E" style={{ display: 'none' }}><i />Soccer</span>
                    <span id="sportsBookEIcon_4" className="game-E" style={{ display: 'none' }}><i />Cricket</span>
                  </dt>
                  <dd id="eventName">{item.eventName} </dd>
                </dl>
              </Link>
              <a id="multiMarketPin" className="pin-off addMultiMarket" data-eventid={32912573} data-marketid={0} href="javascript:void(0)" />
            </li>
            // <li   key={id} id="highlightEvent_29869541" className={`inplay${showColor ? "-on" : "-off"}`}>
            //   <Link   to={`/exchange/play/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
            //     <dl>
            //       <dt>
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span   id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
            //         {isbookmaker &&
            //           <span   id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
            //         {moment() >= moment(item.marketStartTime) && <span   id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium soccer</span>}
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span   id="dateTimeInfo" className="time">In-Play {elapsTime}</span>}
            //         {moment() < moment(item.marketStartTime) && <span   id="dateTimeInfo" className="time">{matchTime}</span>}
            //       </dt>

            //       {/* <span class="in_play">1 - 0</span> */}
            //       <dd   id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            //     </dl>
            //   </Link>
            //   <a   class="pin-off" ></a>
            // </li>
          )
        })}
      </>}
    </React.Fragment>
  )
}
