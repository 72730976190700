import { createBreakpoint } from 'react-use';

const size = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

const useBreakpoint = createBreakpoint(size);

export default useBreakpoint;
