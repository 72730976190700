import React from 'react'

export default function ConfirmBet(props) {
    return (
        <React.Fragment>
            <div id="confirmBetPop" class="overlay" style={{ display: "flex" }}>
                <div class="pop-wrap">
                    <h3   >Please Confirm Your Bet
                    </h3>

                    <dl class="info-bet">
                        <dt>
                            {props.oddsType === 'BACK' && <span id="sideType" class="back">{props.oddsType}</span>}
                            {props.oddsType === 'LAY' && <span id="sideType" class="lay">{props.oddsType}</span>}
                            <h4 id="runnerName">{props.runnerName}</h4>
                        </dt>
                        <dd>
                            <span>Odds</span>
                            <strong id="odds">{props.mOdds}</strong>
                        </dd>
                        <dd>
                            <span>Stake</span>
                            <strong id="stake">{props.coin}</strong>
                        </dd>
                        <dd>
                            <span id="plName">{props.oddsType === 'BACK' ? 'Profit' : 'Liability'}</span>
                            <strong id="pl">{((parseFloat(props.mOdds) - 1) * props.coin).toFixed(2)}

                            </strong>
                        </dd>
                    </dl>

                    <ul class="btn-list">
                        <li   >
                            <a id="back" class="btn" onClick={() => { props.closeConfirmBet(); }}>Back
                            </a>
                        </li>
                        <li>
                            <a id="confirm" class="btn-send" onClick={(e) => {
                                e.preventDefault(); props.marketBet(); props.closeConfirmBet();
                                // props.handleRefreshhh(5000,'Placing bet...',1)
                            }} >Confirm
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}
