import React, { useEffect, useState } from 'react'
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

window.betHistoryView = 1;
window.day = 3;

toast.configure()



export default function Bethistory(props) {
	const [select, setselect] = useState(0)
	const [bethistory, setbethistory] = useState([])
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [sTime, setsTime] = useState('09:00')
	const [eTime, seteTime] = useState('08:59')





	const changeStartDate = (val) => {
		setsDate(val);
	}
	const changeEndDate = (val) => {
		seteDate(val);
	}
	const changeStartTime = (val) => {
		setsTime(val);
	}
	const changeEndTime = (val) => {
		seteTime(val);
	}

	const getBetHistory = () => {

		var start = '';
		var end = '';

		if (window.day === 3) {
			start = sDate + ' ' + sTime + ':00';
			end = eDate + ' ' + eTime + ':00';
		}
		else if (window.day === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		else if (window.day === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}
		let gameType = '';
		if (select === 0) {
			gameType = 'Exchange'
		} else if (select === 1) {
			gameType = 'premium'
		}

		axios.post('https://luckybdt.live/clientapi/newbethistory1', {
			id: props.userid,
			token: props.utoken,
			startDate: start,
			endDate: end,
			view: window.betHistoryView,
			gameType,
		})
			.then(result => {
				if (result.data.length == 0) {
					toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
				}

				if (result.status === 200) {
					setbethistory(result.data);

				}

			}

			).catch(e => {
				//setIsError(true);
			});

	}

	// clear
	useEffect(() => {
		setbethistory([]);
	}, [select]);

	return (

		<React.Fragment>

			<div class="col-left">
				<div class="sub_path">
					<div class="path">

						<ul>
							<li class="path-last"><a >My Account</a></li>
						</ul>
					</div>
					<ul id="sports-path-popup" class="sports-path-popup">
					</ul>
					<ul class="sports-side-menu-list">
						<li><a id="sub_menu_detail" href="/exchange/play/myprofile">My Profile</a></li>
						<li><a id="sub_menu_summary" href="/exchange/play/balance" >Balance Overview</a></li>
						<li><a id="sub_menu_accountCashStatement_log" href="/exchange/play/AccountStatement">Account Statement
						</a></li>
						<li><a id="sub_menu_my_bets" href="/exchange/play/mybets" class="select">My Bets</a></li>
						<li><a id="sub_menu_activity_log" href="/exchange/play/activitylog">Activity Log</a></li>
						<li><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div class="centar-content report">
				<h2>My Bets</h2>
				<ul style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li><a href="/exchange/play/mybets">Current Bets</a></li>
					<li><a class="select" href="/exchange/play/bethistory">Bets History</a></li>
					<li><a href="/exchange/play/ProfitLoss">Profit &amp; Loss
					</a></li>
					<li><a href="/exchange/play/casinoProfitLoss">Casino Report</a></li>
				</ul>
				<ul class="report-tab-wrap" >
					<li onClick={() => { setselect(0) }} className={`report-tab ${(select === 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
						Exchange
					</li>
					<li onClick={() => { setselect(1) }} className={`report-tab ${(select === 1) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
						Sportsbook
					</li>
					<li onClick={() => { setselect(2) }} className={`report-tab ${(select === 2) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
						BookMaker
					</li>
					<li onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
						Binary
					</li>
				</ul>
				<div class="function-wrap">
					<ul class="input-list">
						<div id="statusCondition">
							<li><label>Bet Status:</label></li>
							<li>
								<select name="betStatus" id="betStatus"><option value="2">Settled</option></select>
							</li>
						</div>
						<li><label>Period</label></li>
						<li>

							<ul class="input-list" style={{ display: 'inline-flex' }}>

								<DatePicker
									selectsStart
									dateFormat="yyyy-MM-dd"
									selected={startDate}
									placeholderText="YYYY-MM-DD"
									className="cal-input"
									onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
								/>
								<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

								<DatePicker
									selectsEnd
									dateFormat="yyyy-MM-dd"
									placeholderText="YYYY-MM-DD"
									selected={endDate}
									onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
									className="cal-input"
								/>
								<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


							</ul>


						</li>

					</ul>

					<ul class="input-list">
						<li><a id="today" onClick={() => { window.day = 1; window.betHistoryView = 1; getBetHistory(); }} class="btn">Just For Today</a></li>
						<li><a id="yesterday" onClick={() => { window.day = 2; window.betHistoryView = 1; getBetHistory(); }} class="btn">From Yesterday</a></li>

						<li><a id="getPL" onClick={() => { window.day = 3; window.betHistoryView = 1; getBetHistory(); }} class="btn-send">Get History</a></li>
					</ul>
				</div>
				<div id="report">

					<table id="matchTable" class="table-s" style={{ display: 'table' }}>
						<tbody><tr>
							<th width="9%" class="align-L">Bet ID
							</th>
							<th width="9%" class="align-L">PL ID
							</th>
							<th width="" class="align-L">Market
							</th>
							<th width="12%">Selection
							</th>
							<th width="4%">Type
							</th>
							<th width="8%">Bet placed
							</th>

							<th width="8%">Stake
							</th>
							<th width="8%">Avg. odds matched
							</th>
							<th width="10%">Profit/Loss
							</th>

						</tr>

							{bethistory.map((item, index) => {
								var matchName;
								var runner;
								var odds;
								var profit = '';
								var matchtype;
								if (item.sportId == '4') {
									matchName = 'CRICKET';
								} else if (item.sportId == '1') {
									matchName = 'SOCCER';
								}
								else if (item.sportId == '2') {
									matchName = 'TENNIS';
								}
								if (item.betType == 'fancy') {
									runner = item.runnerName;
									odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
								}
								else if (item.betType == 'match') {
									odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
									if (item.teamName == 'A') {
										runner = item.runnerName1;
									}
									else if (item.teamName == 'B') {
										runner = item.runnerName2;
									}
									else if (item.teamName == 'T') {
										runner = 'The Draw';
									}
								}

								if (item.betType == 'fancy' && item.result) {
									if (item.type == "YES") {
										if (parseFloat(item.rate) <= parseFloat(item.result)) {

											profit = parseFloat(item.amount * item.teamName).toFixed(2);
										}
										else profit = item.amount * (-1);

									}
									else {
										if (parseFloat(item.rate) > parseFloat(item.result)) {
											profit = item.amount;
										}
										else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
									}
								}
								else if (item.betType == 'match' && item.winner) {
									if (item.type == "LAGAI") {
										if (item.teamName == 'A') {
											if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
										else if (item.teamName == "B") {
											if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
										else if (item.teamName == "T") {
											if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
											else profit = item.amount * (-1);
										}
									}
									else if (item.type == "KHAI") {
										if (item.teamName == 'A') {
											if (item.winner != "A") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
										else if (item.teamName == "B") {
											if (item.winner != "B") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
										else if (item.teamName == "T") {
											if (item.winner != "T") profit = item.amount;
											else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
										}
									}

									if ((item.runnerId3 == null || item.runnerId3 == '' || item.runnerId3 == ' ') && item.winner == "T") {
										profit = 0;
									}

								}

								if (item.type === 'LAGAI') {
									matchtype = 'BACK';
								}
								else if (item.type === 'KHAI') {
									matchtype = 'LAY';
								}
								else {
									matchtype = item.type;
								}
								if (select == 1) {
									item.type = 'LAGAI'
									// odds = parseFloat(item.rate).toFixed(2)
									item.betType = 'match'
									runner = item.selectionName
									profit = parseFloat(item.pl).toFixed(2);
									matchName = 'Premium'
								}



								return (
									<tr key={index} id="matchRow0" style={{ display: 'table-row' }}>
										<td class="align-L" id="playerID">{item.clientId}</td>
										<td class="align-L"><a id="betID" href="javascript: void(0);" class="expand-close" onclick="BettingHistoryUtilHandler.toggleTx(0)">{item.id}</a></td>
										<td id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong><img class="fromto" src={Transparent} />{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
										<td id="matchSelection">{runner}</td>
										<td   ><span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
										<td   ><span id="betPlaced" class="small-date">{item.betTime}</span></td>

										<td id="matchStake">{item.amount}</td>
										<td id="matchAvgOdds">{odds}</td>
										<td   ><span id="pol" class="small-date"><span className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit) + ')'}</span></span></td>

									</tr>
								)
							})}







						</tbody>
					</table>
					{bethistory.length > 0 && <p class="table-other">Betting History is shown net of commission.
					</p>}
					{bethistory.length == 0 && <p class="table-other">Betting History enables you to review the bets you have placed.
					</p>}
					{bethistory.length == 0 && <p class="table-other">Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.


					</p>}




					{bethistory.length == 0 && <p style={{ marginTop: '10px' }} class="table-other">Betting History is available online for the past 30 days.


					</p>}




					<div>

						{bethistory.length > 0 && <ul id="pageNumberContent" class="pages">
							<li id="prev"><a onClick={() => { if (window.betHistoryView > 1) { window.betHistoryView = window.betHistoryView - 1; getBetHistory(); } }} className={`${(window.betHistoryView > 1) ? "" : "disable"}`}>Prev</a></li>
							<li id="pageNumber"><a class="select" style={{ pointerEvents: ' none' }}>{window.betHistoryView}</a></li>
							<li id="next"><a onClick={() => { if (bethistory.length >= 50) { window.betHistoryView = window.betHistoryView + 1; getBetHistory(); } }} className={`${(bethistory.length >= 50) ? "" : "disable"}`} >Next</a></li></ul>}
					</div>

				</div>
			</div>
		</React.Fragment>

	)
}
