import React, { useState, useEffect, memo } from 'react';

import { usePrevious } from "react-use";
import { marketKeys } from '../../utils';

const ValueBlinker = memo(({ rate, size, className }) => {
    const [valDetect, setValDetect] = useState(false);
    const prvStateValue = usePrevious(rate);

    useEffect(() => {
        if (rate !== prvStateValue) {
            setValDetect(true)
            setTimeout(() => setValDetect(false), 350)
        }
    }, [rate]);

    return (
        <a className={`${className} ${valDetect ? "spark" : ""}`}>{rate}<span>{size}</span></a>
    )
})

const RunnerRow = ({ className, style = {}, veriant, blockStatus, hideStatus, runnerName, firstPl, secondPl, runnerBackRate = '', runnerBackSize = '', runnerLayRate = '', runnerLaySize = '', onClick, backSelected, laySelected, bookBtn }) => {

    // const blockStyle = veriant != marketKeys.bookmaker ? {
    //     ...(hideStatus ? { display: 'flex', zIndex: 22  } : { display: 'none' })
    // } : {}

    return (
        <dl className={`bets-selections ${className}`} style={{ ...style, display: 'flex' }}>
            {/* {(blockStatus || hideStatus) &&
                <dd class={veriant == marketKeys.bookmaker ? 'suspend-fancy' : 'suspend'}
                style={blockStyle}
                >
                    {blockStatus && (
                        <p>
                            {blockStatus}
                        </p>
                    )}
                </dd>} */}
                {blockStatus}

                {veriant == marketKeys.fancy ?
                    <dt className="line_market-selection">
                        <dl className="tips">
                            <dt>
                                {firstPl}
                            </dt>
                            <dd id="remarkFirstRow" style={{display: ' none'}}></dd>
                            <dd id="remarkSecondRow" style={{display: ' none'}}></dd>
                        </dl>
                        {bookBtn && bookBtn}
                    </dt>
                    : <dt>
                            <h4>{runnerName}</h4>
                            <ul>
                                {firstPl}
                                {secondPl}
                            </ul>
                        </dt>
                }


            <dd className="mode-land">
                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
            </dd>
            <dd className="mode-land">
                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
            </dd>

            {veriant == marketKeys.bookmaker ? (
                <dd class="backlay_box">
                    <dl className="back-gradient" >
                        <dd onClick={() =>  (runnerBackRate != ' ' && runnerBackRate > 0) ? onClick('back', runnerBackRate) : null } style={{ cursor: 'pointer' }}>
                            <ValueBlinker
                                rate={runnerBackRate}
                                size={runnerBackSize}
                                className={`back-1 ${backSelected ? "select" : "null"}`}
                            />
                        </dd>
                        {/* <a className={`back-1 ${backSelected ? "select" : "null"} ${(c1 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{runnerBackRate}<span>{runnerBackSize}</span></a> */}
                    </dl>
                    <dl className="lay-gradient" >
                        <dd onClick={() => (runnerLayRate != ' ' && runnerLayRate > 0) ? onClick('lay', runnerLayRate) : null }>
                            <ValueBlinker
                                rate={runnerLayRate}
                                size={runnerLaySize}
                                className={`lay-1 ${laySelected ? "select" : "null"}`}
                            />
                        </dd>
                        {/* <a className={`lay-1 ${laySelected ? "select" : "null"} ${(c2 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{runnerLayRate}<span>{runnerLaySize}</span></a> */}
                    </dl>
                </dd>
            ) : (
                <>
                    <dd onClick={() => (runnerBackRate != ' ' && runnerBackRate > 0) ? onClick('back', runnerBackRate) : null } style={ veriant == marketKeys.fancy ? {order: 2} : {}}>
                        <ValueBlinker
                            rate={runnerBackRate}
                            size={runnerBackSize}
                            className={`back-1 ${backSelected ? "select" : "null"}`}
                        />
                        {/* <a className={`back-1 ${backSelected ? "select" : "null"} ${(c1 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{runnerBackRate}<span>{runnerBackSize}</span></a> */}
                    </dd>
                    <dd onClick={() => (runnerLayRate != ' ' && runnerLayRate > 0) ? onClick('lay', runnerLayRate) : null }>
                        <ValueBlinker
                            rate={runnerLayRate}
                            size={runnerLaySize}
                            className={`lay-1 ${laySelected ? "select" : "null"}`}
                        />
                        {/* <a className={`lay-1 ${laySelected ? "select" : "null"} ${(c2 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{runnerLayRate}<span>{runnerLaySize}</span></a> */}
                    </dd>
                </>
            )}


            <dd className="mode-land">
                <a className="lay-2" fullmarketodds="1.01"><span /></a>
            </dd>
            <dd className="mode-land">
                <a className="lay-3" fullmarketodds="1.01"><span /></a>
            </dd>
        </dl>
    );
};

export default RunnerRow;
