import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'
import { AnnounceNews } from './AnnounceNews';

export default function ANnouncement(props) {
  const [Announcement, setAnnouncement] = useState([])
  const [openpopup, setopenpopup] = useState(false);

  useEffect(() => {

    axios.post('https://luckybdt.live/clientapi/news', {
      id: props.userid,
      token: props.utoken,

    })
      .then(result => {
        // if(result.status === 210){
        var arr = [];
        //  console.log(result.data);
        result.data.map((item) => {
          arr.push(item)
        })
        // 
        setAnnouncement(arr);
        //  }

      }
      ).catch(e => {
        //setIsError(true);
      });

  }, [])


  const handleOpen = (News) => {
    setopenpopup(News)
  }


  return (
    <React.Fragment>

      {openpopup &&
        <AnnounceNews handleOpen={handleOpen} userid={props.userid} utoken={props.utoken} />}

      <div class="marquee-box" onClick={() => { props.NewsClick() }}>
        <h4>News</h4>
        <div class="marquee">
          <div style={{ width: "100000px", transform: "translateX(314px)", animation: " 70.105s linear 0s infinite normal none running marqueeAnimation-4429646" }} class="js-marquee-wrapper">
            {Announcement.map(function (item, id) {

              var Date = moment(item.date).format('ll');
              return (
                <div key={id} class="js-marquee" style={{ marginRight: ' 0px', float: ' left' }} onClick={() => { handleOpen(true) }}><a>
                  <span>{Date}</span>
                  {item.message}
                </a></div>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
