import React, { useEffect, useState } from 'react'
import Cup from '../images/sprite/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import moment from 'moment'
import Kabaddi from './Kabaddi';
import Support from './Support';
import cricketstup from '../images/playicon/stump.svg'
import soccerstup from '../images/playicon/soccer.svg'
import tennisstup from '../images/playicon/tennis.svg'
import Binary from './Binary';
import Transparent from '../images/sprite/transparent.gif'
import { Link } from 'react-router-dom';
// import horse from '../images/horsel.6a5281de.svg'
// import MT5 from '../images/mt5.4f09b4e8.svg'

import Cookies from 'universal-cookie';
import BasketballMatches from './BasketballMatches';
import Election from './Election';

const cookies = new Cookies();

require('moment-timezone');

export default function Highlights(props) {
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	const [countCricket, setCountCricket] = useState(0);
	const [countSoccer, setCountSoccer] = useState(0);
	const [CountBasketball, setCountBasketball] = useState(0);
	const [countTennis, setCountTennis] = useState(0);
	const [newrefresh, setnewrefresh] = useState(true);
	const [casinoWait, setcasinoWait] = useState(false);


	function changecount(cri, socc, tenn, BasBall) {

		setCountCricket(cri);
		setCountSoccer(socc);
		setCountTennis(tenn);
		setCountBasketball(BasBall);
	}

	useEffect(() => {
		axios.all([
			axios.get('https://luckybdt.live/clientapi/cricket'),
			axios.get('https://luckybdt.live/clientapi/soccer'),
			axios.get('https://luckybdt.live/clientapi/tennis'),
			// axios.get('https://luckybdt.live/clientapi/Basketball')
		])
			.then(axios.spread((obj1, obj2, obj3, obj4) => {

				setnewrefresh(false)
				var count1 = 0;
				var count2 = 0;
				var count3 = 0;
				var count4 = 0;

				obj1.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj2.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})
				obj3.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj4.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj1.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count1 = count1 + 1
					}
				});
				obj2.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count2 = count2 + 1;
					}
				});
				obj3.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count3 = count3 + 1;
					}
				});

				obj4.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count4 = count4 + 1;
					}
				});


				changecount(count1, count2, count3, count4);



			}));
	}, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}


	const openAESexy = (gameI) => {
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
		else {
			var utoken = cookies.get('token');
			var user = cookies.get('id');
			var mil = Date.now();

			if (!user || !utoken) {
				return;
			}
			setnewrefresh(true);
			axios.post('https://luckybdt.live/api/gapcasino/game/url', {
				user: user,
				token: utoken,
				platform: 'GPL_DESKTOP',
				lobby_url: window.location.href,
				game_id: gameI

			})
				.then(result => {

					setTimeout(() => { setnewrefresh(false); }, 4000);
					if (result.data.url) {
						// window.open(result.data.url);
						//var newTab=window.open('about:blank');
						window.location.href = result.data.url;

					}


				})
				.catch(e => {

				});

		}
	}





	return (

		<React.Fragment>
			{newrefresh === true &&
				<div id="poker_loading" class="loading-overlay" style={{ display: 'flex' }}>
					<div class="loading-wrap">
						<div class="loading">
							<div></div>
							<div></div>
						</div>
						<p>Loading...</p>
					</div>
				</div>}

			{newrefresh === false &&
				<div className='mob-main-content'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					<a class="a-search" href="#" onClick={openSearchbar}>Search</a>
					<div id="frame" className="wrap-highlight ps ps--theme_default ps--active-x scroll-frame" data-ps-id="5abd3a57-52c9-1ecb-e745-bdf271a1a2c5" style={{ overflowX: 'scroll' }}> <a className="a-tab_prev" href="#" style={{ display: 'none' }}>prev</a>
						<ul id="label" style={{ left: '0px', paddingRight: '14.33333vw', display: "flex" }}>
							<li class="menu-casino">
								<span class="tag-new">New</span>
								<a id="casinoLoginBtn" class="bg_color-2" href="/casino"><img class="icon-casino" src="https://www.playinexch.com/exchange/images/transparent.gif" />

								</a>
								<span>Casino</span>
							</li>
							<li className={`${(highlightTab === 4) ? "highlightCls sportTab selected" : "highlightCls sportTab"}`} data-sportname="Cricket">

								<span id="tagLive" class="mob-live-number"><strong></strong>{countCricket}</span>
								<a href="#" className={`${(highlightTab === 4) ? "bg_color-3 border_active" : "bg_color-3"}`} onClick={() => { sethighlightTab(4) }}>
									<img className src={cricketstup} /></a>
								<span>Cricket</span>
							</li>
							<li className={`${(highlightTab === 1) ? "highlightCls sportTab selected" : "highlightCls sportTab"}`} data-sportname="Soccer">

								<span id="tagLive" class="mob-live-number"><strong></strong>{countSoccer}</span>
								<a href="#" className={`${(highlightTab === 1) ? "bg_color-4 border_active" : "bg_color-4"}`} onClick={() => { sethighlightTab(1) }}><img className src={soccerstup} /></a>
								<span>Soccer</span>
							</li>
							<li className={`${(highlightTab === 2) ? "highlightCls sportTab selected" : "highlightCls sportTab"}`} data-sportname="Tennis">
								<span id="tagLive" class="mob-live-number"><strong></strong>{countTennis}</span>

								<a href="#" className={`${(highlightTab === 2) ? "bg_color-5 border_active" : "bg_color-5"}`} onClick={() => { sethighlightTab(2) }}>
									<img className src={tennisstup} /></a>
								<span>Tennis</span>
							</li>
							{/* <li className="highlightCls sportTab long-term-bets-sec" data-sportname="long_term_bets">
								<a href="#" className="bg_color-6"><img className="icon-ipl" src="https://www.playinexch.com/sportsbook/images/transparent.gif" /></a>
								<span>Long Term Bets</span>
							</li> */}
						</ul>
						<div className="ps__scrollbar-x-rail" style={{ width: '280px', left: '0px', bottom: '0px' }}>
							<div className="ps__scrollbar-x" tabIndex={0} style={{ left: '0px', width: '237px' }} />
						</div>
						<div className="ps__scrollbar-y-rail" style={{ top: '0px', right: '0px' }}>
							<div className="ps__scrollbar-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
						</div>
					</div>

					{/* <div   id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div   id="frame" className="mob-nav-highlight ps ps--theme_default ps--active-x">


							<ul   id="label">
								{props.isLoggedIn &&
									<li   class="menu-casino">
										<span   class="tag-new">New</span>
										<Link to="/CasinoList" id="casinoLoginBtn" ><img class="icon-casino" src={Transparent} />Casino</Link>
									</li>}

								
								<li   id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span   id="tagLive" className="mob-live-number"><strong></strong>{countCricket}</span>
									<a   ><img   className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

								

								<li   id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span   id="tagLive" className="mob-live-number"><strong></strong>{countSoccer}</span>
									<a   ><img   className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>
							


							

								<li   id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span   id="tagLive" className="mob-live-number"><strong></strong>{countTennis}</span>
									<a   ><img   className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>

								


								<li   id="highlightTab3" onClick={() => { sethighlightTab(138) }} className={`${(highlightTab === 138) ? "select" : "null"}`}>
									<a   ><img   class="icon-kabaddi" src={Transparent} />Kabaddi</a>
								</li>
							
							</ul>

						
							<div   className="ps__scrollbar-x-rail"><div   className="ps__scrollbar-x"  ></div></div><div   className="ps__scrollbar-y-rail" ><div   className="ps__scrollbar-y"  ></div></div></div>
					</div> */}
					<div class="ex-sports">
						<ul>
							<li class="selected"><a href="javascript:void(0);">Exchange</a></li>
							<li><a href="/mobile/sportsbook/highlight" onclick="loader(1)">Sportsbook</a></li>
						</ul>
					</div>
					<div className="wrap-highlight_list" style={{ height: 'auto', backgroundColor: 'pink' }}  >
						<h3>  Highlights</h3>

						<ul id="highlightList" className="highlight-list event_list event_4">
							{highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 7522 && <BasketballMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2378962 && <Election isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 138 && <Kabaddi isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
						</ul>
					</div>
					{/* <Support handlepolicy={props.handlepolicy} /> */}

				</div>}
		</React.Fragment>
	)
}
