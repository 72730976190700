import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment'
import InPlayListItem from "./components/in-play-list-item/in-play-list-item";
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
require('moment-timezone');

export default function Inplay(props) {
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])





  useEffect(() => {

    // axios
    //   .get(`https://luckybdt.live/clientapi/Basketball`)

    //   .then((result) => {
    //     result.data.map(item => {
    //       item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
    //       return item;
    //     })

    //     result.data.sort(function (a, b) {
    //       return moment(a.marketStartTime) - moment(b.marketStartTime);
    //     });
    //     var obj = [];
    //     result.data.map((item) => {
    //       if (moment() >= moment(item.marketStartTime)) {
    //         obj.push(item);
    //       }


    //     });
    //     setBasketball(obj);
    //   })
    //   .catch((error) => console.log(error));
    axios
      .get(`https://luckybdt.live/clientapi/tennis`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setTennis(obj);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://luckybdt.live/clientapi/soccer`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log(error));

    axios
      .get(`https://luckybdt.live/clientapi/cricket`)

      .then((result) => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }

        });

        setcricket(obj);
      })

      .catch((error) => console.log(error));

    axios.get(
      `https://luckybdt.live/clientapi/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://luckybdt.live/clientapi/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios
      .get(`https://luckybdt.live/clientapi/fancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log(error));


    axios.get(
      `https://luckybdt.live/clientapi/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);




  return (
    <React.Fragment>

      <LoadingOverlay
        show={refresh === true}
      />

      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false &&
        <div className="wrap-highlight_list event_list event_live" id="inPlayData" style={{ marginBottom: '100px' }}>

          {cricket.length > 0 &&
            <div id="inPlayEventType_4" style={{ display: "block" }}>
              <div class="game_icons">
                <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/4-icon.png" />
                <h3>Cricket</h3>
              </div>

              <ul className="highlight-list" id="inPlayList_4">
                {cricket.map(function (item, id) {

                  let customScore = '';
                  let elapsTime = '';
                  let filteredData = ScoreData &&
                    ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                  // let filteredData = [];
                  if (filteredData && filteredData.length > 0) {
                    customScore = filteredData[0].score;
                    elapsTime = filteredData[0].timeElapsed
                  }

                  let isFancy = false, showtv = false, isbookmaker = false;

                  if (fancy.includes(item.eventId)) {
                    isFancy = true;
                  }

                  if (tv.includes(item.eventId)) {
                    showtv = true;
                  }
                  if (bookmaker.includes(item.eventId)) {
                    isbookmaker = true;

                  }

                  return (
                    <React.Fragment key={id}>
                      {moment() >= moment(item.marketStartTime) && (
                        <InPlayListItem
                          isInplay
                          sportsId={4}
                          cricket={cricket.length}
                          eventId={item.eventId}
                          marketId={item.marketId}
                          linkOnClick={() => props.changeState(true)}
                          isTv={showtv}
                          isFancy={isFancy}
                          isBookmaker={isbookmaker}
                          score={customScore}
                          runnerName1={item.runnerName1}
                          runnerName2={item.runnerName2}

                        />
                      )}
                    </React.Fragment>
                  );
                })}

              </ul>
            </div>}

          {Soccer.length > 0 && <div id="inPlayEventType_1" style={{ display: "block" }}>
            <div class="game_icons">
              <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/1-icon.png" />
              <h3>Soccer</h3>
            </div>
            <ul className="highlight-list" id="inPlayList_1">
              {Soccer.map(function (item, id) {
                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }

                let showtv = false, isbookmaker = false;

                if (tv.includes(item.eventId)) {
                  showtv = true;
                }
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;
                }

                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) && (
                      <InPlayListItem
                        isInplay={moment() >= moment(item.marketStartTime)}
                        isPremium
                        Soccer={Soccer.length}
                        sportsId={1}
                        eventId={item.eventId}
                        marketId={item.marketId}
                        linkOnClick={() => props.changeState(true)}
                        isTv={showtv}
                        isBookmaker={isbookmaker}
                        score={customScore}
                        runnerName1={item.runnerName1}
                        runnerName2={item.runnerName2}

                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>}

          {Tennis.length > 0 && <div id="inPlayEventType_2" style={{ display: "block" }}>
            <div class="game_icons">
              <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/2-icon.png" />
              <h3>Tennis</h3>
            </div>
            <ul className="highlight-list" id="inPlayList_2">

              {Tennis.map(function (item, id) {
                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }

                let showtv = false, isbookmaker = false;

                if (tv.includes(item.eventId)) {
                  showtv = true;
                }
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;
                }

                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) && (
                      <InPlayListItem
                        isInplay={moment() >= moment(item.marketStartTime)}
                        isPremium
                        sportsId={2}
                        Tennis={Tennis.length}
                        eventId={item.eventId}
                        marketId={item.marketId}
                        linkOnClick={() => props.changeState(true)}
                        isTv={showtv}
                        isBookmaker={isbookmaker}
                        score={customScore}
                        runnerName1={item.runnerName1}
                        runnerName2={item.runnerName2}

                      />
                    )}
                  </React.Fragment>
                );
              })}

            </ul>
          </div>}

          {/* basketball */}

          {/* {Basketball.length > 0 && <div  id="inPlayEventType_2" style={{ display: "block" }}>
          <h3  >Basketball</h3>
          <ul  className="mob-sports-highlight-list" id="inPlayList_2">




            {Basketball.map(function (item, id) {

        let customScore = '';
        let elapsTime = '';
        let filteredData = ScoreData &&
 ScoreData.filter(x => String(x.eventId) === String(item.eventId));
        // let filteredData = [];
        if (filteredData && filteredData.length > 0) {
          customScore = filteredData[0].score;
          elapsTime = filteredData[0].timeElapsed
        }


              var showColor = false;

              var showtv = false;
              var isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }
              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <li
                      id="inPlayEvent_-10065496"
                      key={id}
                      className={`inplay-on`}
                    >
                      <Link  to={`/exchange/play/fullmarket/7522/${item.eventId}/${item.marketId}`}
                        id="info"
                        onClick={() => {
                          props.changeState(true);
                        }}
                      >
                        <dl  >
                          <dt  >
                            {showtv && <span id="streamingIcon" className="game-live">
                              game-live
                            </span>}

                            {isbookmaker &&
                              <span  id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

<span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            {moment() >= moment(item.marketStartTime) && (
                              <span  id="inPlayStatus" className="time">
                                In-Play
                              </span>
                            )}


                          </dt>
                          <dd   id="eventName">{item.runnerName1} <span className="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                        </dl>
                      </Link>
                      <a  id={MKPINTN + id} className="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div>} */}

        </div>}

      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
          <h3>There are currently no matches in Play.</h3>
          <p>Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  );
}
