import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
import sportsicons from '../images/playicon/2_game-icon.png'
import sportsoff from '../images/playicon/2_game-officon.png'
require('moment-timezone');

const cookies = new Cookies();

export default function TennisMatches(props) {

  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])


  // vvvv


  useEffect(() => {

    axios.get(
      `https://luckybdt.live/clientapi/tennis`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }


      })


      .catch(error => console.log(error));

    axios.get(
      `https://luckybdt.live/clientapi/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://luckybdt.live/clientapi/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://luckybdt.live/clientapi/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));

  }, []);



  return (

    <React.Fragment>

      {newrefresh === true &&
        <div class="loading-overlay" id="loading">
          <div class="loading-wrap" style={{ display: " flex" }}>
            <div class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}



      {newrefresh === false && <>
        <div class="game_ban"><img alt="" src="https://s3.ap-south-1.amazonaws.com/tokyo.assets/sporticon/2.png?v=1" /></div>

        {match.map(function (item, id) {
          let customScore = '';
          let elapsTime = '';
          let filteredData = ScoreData &&
            ScoreData.filter(x => String(x.eventId) === String(item.eventId));
          // let filteredData = [];
          if (filteredData && filteredData.length > 0) {
            customScore = filteredData[0].score;
            // elapsTime = filteredData[0].timeElapsed;
          }

          var showtv = false;
          var isbookmaker = false;

          var showInplay = false

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }


          if (tv.includes(item.eventId)) {
            showtv = true;
          };
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          };


          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showInplay = true;
          }
          return (
            <li id="highlightEvent_32912573" style={{}} className={`event_list event_4 ${showInplay ? "inplay-on" : "inplay-off"}`} >
              <Link to={`/exchange/play/fullmarket/2/${item.eventId}/${item.marketId}`} id="info" href="/mobile/eventDetails/32912573?type=4">
                {showInplay ? <img alt="" src={sportsicons} /> : <img alt="" src={sportsoff} />}

                <dl>
                  <dt>
                    {moment() >= moment(item.marketStartTime) && <span id="streamingIcon" className="game-live" >game-live</span>}
                    <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>
                    {/* {isFancy && <span id="fancyBetIcon" className="game-fancy" >Fancy</span>} */}
                    {isbookmaker && <span id="bookMakerIcon" className="game-bookmaker" style={{ display: 'flex' }}>BookMaker</span>}
                    <span id="feedingSiteIcon" className="game-sportsbook" style={{ display: 'none' }}>Sportsbook</span>
                    <span id="sportsBookIcon_1" className="game-sportsbook" style={{ display: 'none' }}>Premium Tennis</span>
                    <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: 'none' }}>Premium Cricket</span>
                    <span id="dateTimeInfo" className="time">{matchTime}</span>
                    <span id="sportsBookEIcon_1" className="game-E" style={{ display: 'none' }}><i />Soccer</span>
                    <span id="sportsBookEIcon_4" className="game-E" style={{ display: 'none' }}><i />Cricket</span>
                  </dt>
                  <dd id="eventName">{item.eventName} </dd>
                </dl>
              </Link>
              <a id="multiMarketPin" className="pin-off addMultiMarket" data-eventid={32912573} data-marketid={0} href="javascript:void(0)" />
            </li>

            // <li   key={id} id="highlightEvent_29869541" className={`inplay${showInplay ? "-on" : "-off"}`}>
            //   <Link   to={`/exchange/play/fullmarket/2/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>
            //     <dl>
            //       <dt>
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span   id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
            //         {isbookmaker &&
            //           <span   id="bookMakerIcon" className={`game-bookmaker ${showInplay ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
            //         {moment() >= moment(item.marketStartTime) && <span   id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Tennis</span>}
            //         {moment() >= moment(item.marketStartTime) &&
            //           <span   id="dateTimeInfo" className="time">In-play</span>}

            //         {moment() < moment(item.marketStartTime) &&
            //           <span   id="dateTimeInfo" className="time">{matchTime}</span>}
            //       </dt>
            //       <dd   id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
            //     </dl>
            //   </Link>
            //   <a    class="pin-off"  d></a>

            // </li>

          )
        })}
      </>}
    </React.Fragment>
  )
}
