import React from "react";
import { Link } from "react-router-dom";

const InPlayListItem = ({ pinId, cricket, Tennis, Soccer, sportsId, eventId, marketId, linkOnClick, pinOnClick, isTv, isFancy, isFancyClassName, isBookmaker, isBookmakerClassName, score, runnerName1, runnerName2, isPremium, isInplay, isLowLiquidity, time, liClassName }) => {

    return (
        <li className="inplay-on">
            <Link to={`/exchange/play/fullmarket/${sportsId}/${eventId}/${marketId}`} onClick={linkOnClick}>
                {cricket > 0 && <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/4_game-icon.png" />}
                {Soccer > 0 && <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/1_game-icon.png" />}
                {Tennis > 0 && <img alt="" src="https://www.playinexch.com/exchange/images/sportsicons/2_game-icon.png" />}
                <dl> <dt>
                    {isTv && <span id="streamingIcon" className="game-live">game-live</span>}
                    <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>
                    {isFancy && (<span id="fancyBetIcon" className="game-fancy" style={{ display: 'flex' }}>Fancy</span>)}
                    {isBookmaker && <span id="bookMakerIcon" className="game-bookmaker" style={{ display: 'flex' }}>BookMaker</span>}
                    <span id="feedingSiteIcon" className="game-sportsbook" style={{ display: 'none' }}>Sportsbook</span>
                    <span id="sportsBookIcon_1" className="game-sportsbook" style={{ display: 'none' }}>Premium Tennis</span>
                    <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: 'none' }}>Premium Cricket</span>
                    <span id="inPlayStatus_-10044847" className="time">In-Play</span>
                    <span id="sportsBookEIcon_1" className="game-E" style={{ display: 'none' }}><i />Soccer</span>
                    <span id="sportsBookEIcon_4" className="game-E" style={{ display: 'none' }}><i />Cricket</span>
                </dt>
                    <dd id="eventName">{runnerName1}
                        <span className="in_play">{score !== '' ? score : ' vs '}</span>
                        {runnerName2}</dd>
                </dl>
            </Link>
            <a id="multiMarketPin" className="pin-off addMultiMarket" data-marketid={0} data-eventid={32855066} href="#" />
        </li >
        // <li
        //     // id="inPlayEvent_30062394"
        //     className={`${liClassName ? liClassName : 'inplay-on'}`}
        // >
        //     <Link
        //         to={`/exchange/play/fullmarket/${sportsId}/${eventId}/${marketId}`}
        //         // id="info"
        //         onClick={linkOnClick}
        //     >
        //         <dl>
        //             <dt>
        //                 {isTv && <span
        //                     // id="streamingIcon"
        //                     className="game-live"
        //                     style={{ display: "flex" }}
        //                 >
        //                     game-live
        //                 </span>}
        //                 {isLowLiquidity && <span className="game-low_liq"
        //                     style={{ display: 'none' }}>Low Liquidity</span>}
        //                 {isFancy && (
        //                     <span
        //                         // id="fancyBetIcon"
        //                         className={`game-fancy in-play ${isFancyClassName}`}
        //                         style={{ display: "flex" }}
        //                     >
        //                         <pre>in-play</pre>Fancy
        //                     </span>
        //                 )}

        //                 {isBookmaker &&
        //                     <span
        //                         // id="bookMakerIcon"
        //                         className={`game-bookmaker in-play ${isBookmakerClassName}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
        //                 {isPremium &&
        //                     <span className="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>}
        //                 {isInplay && (
        //                     <span
        //                         // id="inPlayStatus_30062394"
        //                         className="time">
        //                         In-Play
        //                     </span>
        //                 )}
        //                 {time && <span className="time">{time}</span>}
        //             </dt>
        //             <dd
        //             // id="eventName"
        //             >{runnerName1}
        //                 <span className="in_play">{score !== '' ? score : ' vs '}</span>
        //                 {runnerName2}</dd>
        //         </dl>
        //     </Link>
        //     <a id={pinId} className={`pin-off`} onClick={pinOnClick}></a>
        // </li>
    )
}

export default InPlayListItem;
