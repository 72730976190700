import React from 'react';

const PremiumRunnerRow = ({ selectionName, gameStatus, isSelect, odds, onClickBack, firstPl, secondPl }) => {

    return (
        <div className={`list-row-cnt-lft sb-odm accept_tickets ${(isSelect) ? "select" : "null"}`} data-event_id="sr:match:42343329" data-outcome_url="srmatch42343329651inningnr1playersrplayer21239501806" data-outcome_id={1806}>
            <div className="cnt-lft-cnt">
                <h4 className="outcomeDescription">{selectionName} <ul >
                    {firstPl}
                    {secondPl}
                </ul></h4>
            </div>
            <div className="cnt-rgt-cnt sb-ovalue oddsChange changeIndicators">
                <h4 className="oddsVal" onClick={onClickBack}>{odds}</h4>

            </div>
        </div>
        // <dl id="sportsBookSelection_2_1886757_97016100_1813977990" className="bets-selections" style={{ display: 'flex' }} eventid={1886757} marketpk="2_1886757_97016100" isactive={1} status={1}>
        //     <dt>
        //         <h4 id="selectionName">{selectionName}</h4>
        //         <ul >
        //             {firstPl}
        //             {secondPl}
        //         </ul>
        //         {/*<ul>*/}
        //         {/*    */}
        //         {/*    <li id="before" className="win" style={{ display: 'none' }} />*/}
        //         {/*    <li id="after" className="to-lose" style={{ display: 'none' }} />*/}
        //         {/*</ul>*/}
        //     </dt>
        //     {(gameStatus != 2) && <dd id="suspend" className="suspend"><p id="info">{gameStatus == 10 ? 'Ball Running' : 'Suspend'}</p></dd>}

        //     {/* <dd id="suspend" className="suspend" style={{ display: 'none' }} isactive={1} status={1}><p>Suspend</p></dd> */}
        //     <dd>
        //         {/* setPreOdds(item2.odds); */}
        //         <a id="back_1" onClick={onClickBack}
        //            className={`back-1 ${(isSelect) ? "select" : "null"}`}>
        //             <span>{odds}</span>
        //         </a>
        //     </dd>
        //     <dd className="mode-land" />
        // </dl>
    )
}

export default PremiumRunnerRow;
