import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'

export const AnnounceNews = (props) => {

    const [Announcement, setAnnouncement] = useState([])

    useEffect(() => {

        axios.post('https://luckybdt.live/clientapi/news', {
            id: props.userid,
            token: props.utoken,

        })
            .then(result => {
                // if(result.status === 210){
                var arr = [];
                //  console.log(result.data);
                result.data.map((item) => {
                    arr.push(item)
                })
                //
                setAnnouncement(arr);
                //  }

            }
            ).catch(e => {
                //setIsError(true);
            });

    }, [])



    return (


        <React.Fragment>

            <div id="announcementPopUp" className="overlay">
                <div className="mob-news-popup marquee-pop">
                    {/* mob-news-popup */}

                    {/* <header class="announce-header"><h1>Announcement</h1><a class="close" href="vueRiskManagement.html">Close</a></header>  */}

                    <div className="side-head">
                        <h3 className="a-depth">Announcement</h3>
                        <a className="close ui-link" onClick={() => { props.handleOpen(false) }} href="javascript:$j('#announcementPopUp').css('display', 'none');">Close</a>
                    </div>

                    <div className="mob-announce-content" id="announcementContent">
                        {Announcement.map(function (item, id) {

                            var Date = moment(item.date).format('ll');
                            const myArray = Date.split(" ", 3);


                            var FinalDate = myArray[1].replace(/,\s*$/, "");

                            return (
                                <div id="announcementTemplate" className="article-wrap">
                                    <dl className="article-date">
                                        <dt id="date_0">{FinalDate}</dt>
                                        <dd id="month_0">{myArray[0]}</dd>
                                        <dd id="year_0">{myArray[2]}</dd>
                                    </dl>
                                    <p id="textByLanguageType_0">{item.message}</p>
                                </div>
                            )
                        })}



                    </div>
                    <div>
                        <ul id="pageNumberContent" className="pages">
                            <li id="prev">
                                <a className="ui-link disable" style={{ pointerEvents: 'none' }}>Prev</a></li>
                            <li id="pageNumber">
                                <a className="ui-link select" style={{ pointerEvents: 'none', backgroundColor: '#222222', color: '#rgb(254 184 9)' }}>1</a></li>
                            <li id="next">
                                <a className="ui-link disable" style={{ pointerEvents: 'none' }}>Next</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
