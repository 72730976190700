import React, { useEffect, useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'


const io = require('socket.io-client');
const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
  ENDPOINT = "https://luckybdt.live:2053";
}
else if (ranNum == 2) {
  ENDPOINT = "https://luckybdt.live:2083";
}
else if (ranNum == 3) {
  ENDPOINT = "https://luckybdt.live:2087";
}
else {
  ENDPOINT = "https://luckybdt.live:2096";
}

var socket;
var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;
let eventid = "28127348";
export default function WinnerMarket() {
  const [winnerData, setwinnerData] = useState([]);
  const [totalStake, settotalStake] = useState(0);

  useEffect(() => {
    socket = io(ENDPOINT);
    console.log('socket connected');
    socket.on('connect', function (data) {
      socket.emit('room1', eventid);
      console.log('data', data);
    });
    return () => {
      socket.close();
      console.log('socket disconnected');
      // props.setOnClick(0);
      s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
      s25 = -1; s26 = -1; s27 = -1; s28 = -1; s29 = -1; s30 = -1; s31 = -1; s32 = -1; s33 = -1; s34 = -1; s35 = -1; s36 = -1;
    }
  }, [])

  useEffect(() => {

    socket.on(eventid, (val) => {

      var value = JSON.parse(val);
      if (value[0].messageType == 'Winner_market_rate') {
        let temp = value;
        console.log(temp, 'temp');
        settotalStake(temp[0].totalMatched);
        setwinnerData(temp);
      }

    })

    return () => {
      socket.removeAllListeners(eventid);


    }


  }, [eventid])

  return (
    <>
      <div _ngcontent-njs-c67 id="mob-main-content" className="mob-main-content" eventtype={4} eventid={31102920} marketid="1.192573657">
        <div _ngcontent-njs-c67 id="naviMarket" className="mob-market-type ps ps--theme_default" data-ps-id="dd7c990b-079c-38b8-0677-cc1ec473ee97">
          <ul _ngcontent-njs-c67 id="naviMarketList">
            <li _ngcontent-njs-c67 id="naviMarket_1_192573659" className="null" style={{ display: 'list-item' }}><a>Winner</a></li>
          </ul>
        </div>
        <div _ngcontent-njs-c67 id="marketBetsWrap" className="bets-wrap" style={{}}>
          <a id="minMaxButton" className="bet-limit" />
          <dl _ngcontent-njs-c67 id="betsHead" className="bets-selections-head">
            <dt _ngcontent-njs-c67>
              <a _ngcontent-njs-c67 className="a-depth" id="marketDepthBtn">Markets Depth</a>
              <p _ngcontent-njs-c67><span>Matched</span><strong id="totalMatched">USD {totalStake}</strong></p>
            </dt>
            <dd _ngcontent-njs-c67 className="mode-land" />
            <dd _ngcontent-njs-c67 className="mode-land" />
            <dd _ngcontent-njs-c67>Back</dd>
            <dd _ngcontent-njs-c67>Lay</dd>
            <dd _ngcontent-njs-c67 className="mode-land" />
            <dd _ngcontent-njs-c67 className="mode-land" />
          </dl>


          {winnerData.map(function (item, index) {
            return (
              <dl _ngcontent-njs-c67 id="selection_37302" className="bets-selections null" style={{ display: 'flex' }}>
                <dt _ngcontent-njs-c67>
                  <h4 _ngcontent-njs-c67 id="runnerName">{item.runnerName}</h4>
                  <ul _ngcontent-njs-c67 id="winLoss" />
                </dt>
                <dd _ngcontent-njs-c67 id="back_3" className="mode-land">
                  <a className="back-3">{item.runner1BackRate1}<span>{item.runner1BackSize1}</span></a>
                </dd>
                <dd _ngcontent-njs-c67 id="back_2" className="mode-land">
                  <a className="back-2">{item.runner1BackRate2}<span>{item.runner1BackSize2}</span></a>
                </dd>
                <dd _ngcontent-njs-c67 id="back_1">
                  <a className="back-1 null spark">{item.runner1BackRate3}<span>{item.runner1BackSize3}</span></a>
                </dd>
                <dd _ngcontent-njs-c67 id="lay_1">
                  <a className="lay-1 null spark">{item.runner1LayRate1}<span>{item.runner1LaySize1}</span></a>
                </dd>
                <dd _ngcontent-njs-c67 id="lay_2" className="mode-land">
                  <a className="lay-2">{item.runner1LayRate2}<span>{item.runner1LaySize2}</span></a>
                </dd>
                <dd _ngcontent-njs-c67 id="lay_3" className="mode-land">
                  <a className="lay-3">{item.runner1LayRate3}<span>{item.runner1LaySize3}</span></a>
                  <a _ngcontent-njs-c67 className="lay-3" fullmarketodds="1.01"><span /></a>
                </dd>
              </dl>
            )
          })}

        </div>
      </div>



    </>
  )
}
