import React, { useEffect, useState } from 'react'
import Cup from '../images/sprite/cup.gif'
import { Link } from 'react-router-dom';
import Footer from './Footer'
import Axios from 'axios'
import Cookies from 'universal-cookie';
import moment from 'moment'
import ConfirmBet from './ConfirmBet'

require('moment-timezone');

var qs = require('qs');
const cookies = new Cookies();
var matchEndedMarket = false;
var matchEndedBook = false;
const io = require('socket.io-client');


const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
    ENDPOINT = "https://luckybdt.live:2053";
}
else if (ranNum == 2) {
    ENDPOINT = "https://luckybdt.live:2083";
}
else if (ranNum == 3) {
    ENDPOINT = "https://luckybdt.live:2087";
}
else {
    ENDPOINT = "https://luckybdt.live:2096";
}
var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24;
export default function Multimarket(props) {


    const [match, setmatch] = useState([]);
    const [refresh, setrefresh] = useState(true);

    const [noMarket, setnoMarket] = useState(false);
    const [betSlip, setbetSlip] = useState(false);
    const [betTie, setbetTie] = useState(false);

    const [matchUpdate, setmatchUpdate] = useState(false);



    const openbetslip = (index) => { setbetSlip(true); }
    const closebetslip = () => { setbetSlip(false); }
    const betPlace = () => { setbetSlip(false); }
    const [openmatchtype, setopenmatchtype] = useState("")

    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [Maxstake, setMaxstake] = useState(false)
    const [Bookstake, setBookstake] = useState(false)
    const [Fancypin, setFancypin] = useState(false)
    const [bookpin, setbookpin] = useState(false)
    const [livemarpin, setlivemarpin] = useState(false)
    const [marketData, setmarketData] = useState({})
    const [fancyData, setfancyData] = useState([])
    const [defaultStake, setdefaultStake] = useState('');
    const [dStake, setdStake] = useState('');
    const [selectFancy, setselectFancy] = useState(null)
    const [bookData, setbookData] = useState({})
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [click, setclick] = useState(-1);
    const [bOdds, setbOdds] = useState(' ')
    const [mOdds, setmOdds] = useState(' ')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('A')
    const [bookData2, setbookData2] = useState({})
    const [field, setfield] = useState(0);
    const [Bookclick, setBookclick] = useState(false)
    const [Bookclick2, setBookclick2] = useState(false)
    const [Bookclick3, setBookclick3] = useState(false)
    const [MatchoddsData, setMatchoddsData] = useState({})
    const [Matchoddsclick, setMatchoddsclick] = useState(false)
    const [Matchoddsclick2, setMatchoddsclick2] = useState(false)
    const [Matchoddsclick3, setMatchoddsclick3] = useState(false)
    const [selectMatchBack, setselectMatchBack] = useState(null)
    const [selectBack, setselectBack] = useState(null)
    const [selectLay, setselectLay] = useState(true)
    const [showConfirmBet, setshowConfirmBet] = useState(false)
    const [showFancyBook, setshowFancyBook] = useState(false)
    const [fancySelection, setfancySelection] = useState(null)
    const [fancyRunner, setfancyRunner] = useState(null)


    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)

    const [runner1BackRate1, setrunner1BackRate1] = useState([]);
    const [runner1BackSize1, setrunner1BackSize1] = useState([]);
    const [runner1LayRate1, setrunner1LayRate1] = useState([]);
    const [runner1LaySize1, setrunner1LaySize1] = useState([]);
    const [runner2BackRate1, setrunner2BackRate1] = useState([]);
    const [runner2BackSize1, setrunner2BackSize1] = useState([]);
    const [runner2LayRate1, setrunner2LayRate1] = useState([]);
    const [runner2LaySize1, setrunner2LaySize1] = useState([]);

    const [tieBackRate1, settieBackRate1] = useState([]);
    const [tieLayRate1, settieLayRate1] = useState([]);
    const [tieBackSize1, settieBackSize1] = useState([]);
    const [tieLaySize1, settieLaySize1] = useState([]);
    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])

    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState({})
    const [newrefresh, setnewrefresh] = useState(true);
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [liquidityStatus, setliquidityStatus] = useState(0);
    const [fancyStakeInfo, setfancyStakeInfo] = useState(-1);
    const [coinFactor, setcoinFactor] = useState(1);


    useEffect(() => {
        // window.location.reload();
        var list = JSON.parse(localStorage.getItem("multi") || "[]");

        var obj = [];
        // console.log(list);

        Axios.post('https://luckybdt.live/clientapi/getMultiMarket', {
            eventId: list,
        }).then(result => {

            result.data.map((item) => {
                item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                obj.push(item.eventId);
                setmarketData(item);
                return item;
            });

            setmatch(result.data);
            // console.log("okpm-", result.data)
            setrefresh(false);

            localStorage.setItem("multi", JSON.stringify(obj));

        }).catch(e => { });


    }, [matchUpdate]);
    useEffect(() => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");
        socket = io(ENDPOINT);
        // console.log('socket connected');
        socket.on('connect', function (data) {
            socket.emit('room1', list);
        });
        return () => {
            socket.close();
            // console.log('socket disconnected');
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
        }
    }, [])

    useEffect(() => {

        if (!props.userid) return;
        Axios.post('https://luckybdt.live/clientapi/ssgetclickstake', {

            id: props.userid,
            token: props.utoken,

        })
            .then(result => {

                if (result.status === 210) {

                    setcoinFactor(parseInt(result.data[0].coin_type));
                    setdStake(result.data[0].defaultStake);
                    setSsstake1(result.data[0].SSstake1);
                    setSsstake2(result.data[0].SSstake2);
                    setSsstake3(result.data[0].SSstake3);
                    setSsstake4(result.data[0].SSstake4);
                    setSsstake5(result.data[0].SSstake5);
                    setSsstake6(result.data[0].SSstake6);

                }
            }


            ).catch(e => {

            });

        return () => {

        }
    }, [props.userid]);
    const handleRefresh = () => {

        const utoken = cookies.get('token');
        const user = cookies.get('id');
        Axios.post('https://luckybdt.live/clientapi/fancy', {
            id: user,
            token: utoken
        })
            .then(result => {
                if (result.status === 210) {

                    props.changeBalance(result.data[0].val - Math.abs(result.data[1].val));
                    if (result.data[1].val) {

                        props.changeExposure(Math.abs(result.data[1].val));
                    }
                    else {
                        props.changeExposure(0);
                    }
                }

                if (result.status === 220) {
                    // console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    };


    const Multimarketpin = (index, event_id) => {


        var list = JSON.parse(localStorage.getItem("multi") || "[]");
        for (let i = 0; i < list.length; i++) {
            if (list[i] == event_id) {
                let j = i + 1;
                // ctr = 1;
                for (; i < list.length; j++, i++) {
                    list[i] = list[j];
                }
                list.splice([i - 1]);
            }
        }

        localStorage.setItem("multi", JSON.stringify(list));
        setmatchUpdate(!matchUpdate);

        // console.log('2', list);
        //localStorage.clear();

    }

    useEffect(() => {
        //console.log(defaultStake);

        if (click) {

            var amount = defaultStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(mOdds - 1);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(mOdds - 1);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(mOdds - 1);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);



        }

        else if (Bookclick || Bookclick2 || Bookclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }

    }, [defaultStake])

    const refershLoader = () => {
        setrefresh(true);
        setmatchUpdate(!matchUpdate);
    }
    const closeMatchoddsBet = () => {

        setselectMatchBack(0);
        setMatchoddsclick2(false);
        setMatchoddsclick(false)
        setMatchoddsclick3(false);
        setbetSlip(false);
        setclick(-1);



    }
    const marketBetClicked = () => {
        setshowConfirmBet(true);
    }
    const changeDefaultStake = (event) => {

        setdefaultStake(event.target.value);


    }
    const changeMatchodds = (index, val1, val2, val3) => {



        // if (!props.isLoggedIn) {
        //     props.checkShowLogin(true);
        //     return;
        // }

        setdefaultStake(dStake);
        setclick(index);
        if (val2 === 2) {
            setMatchoddsclick(true);
            setMatchoddsclick2(false);
            setMatchoddsclick3(false);
            setteamName('B');

        }
        else if (val2 === 1) {
            setMatchoddsclick2(true);
            setMatchoddsclick(false);
            setMatchoddsclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {
            setMatchoddsclick3(true);
            setMatchoddsclick2(false);
            setMatchoddsclick(false);

            setteamName('T');
        }

        setselectMatchBack(val1);
        setmOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const marketBet = () => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");

        var obj = [];

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');

        Axios.post('https://luckybdt.live/api3/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: list,
            marketId: marketData.marketId,
            odds: mOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet


        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2, amount: defaultStake, rate: mOdds });
                    handleRefresh();
                    plMarket();
                }
                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == '8') {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });
    };



    //    const marketBetClicked = ()=>{
    //         setshowConfirmBet(true);
    //    }


    const closeConfirmBet = () => {
        setshowConfirmBet(false);
    }



    const plMarket = () => {
        var list = JSON.parse(localStorage.getItem("multi") || "[]");



        if (!props.userid) return;
        Axios.post('https://luckybdt.live/clientapi/plmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: list,
            marketId: marketData.marketId

        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlMarket(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }

    useEffect(() => {


        if (marketData.matchStatus == 1) plMarket();

        return () => {

            setpnlMarket({});

        }
    }, [props.userid, bookData2.marketId, marketData.marketId])


    useEffect(() => {

        var list = JSON.parse(localStorage.getItem("multi") || "[]");
        // console.log("again list", list)
        for (let i = 0; i < list.length; i++) {
            socket.on(list[i], (val) => {
                var value = JSON.parse(val);
                // console.log("value data:-", value)
                if (value.messageType === 'betfair_match_rate') {
                    setmarketBetStatus(value.status);
                    if (value.status == '0' && liquidityStatus == 0) {
                        setliquidityStatus(1);
                    }

                    if (s1 !== value.runner1BackRate1) {
                        runner1BackRate1[i] = (value.runner1BackRate1);
                        s1 = value.runner1BackRate1;
                        c1 = 1;
                        setTimeout(() => { c1 = 0; }, 700);
                    }

                    if (s2 !== value.runner1BackSize1) {
                        runner1BackSize1[i] = (value.runner1BackSize1);
                        s2 = value.runner1BackSize1;
                    }

                    if (s3 !== value.runner1LayRate1) {
                        runner1LayRate1[i] = (value.runner1LayRate1);
                        s3 = value.runner1LayRate1;
                        c2 = 1;
                        setTimeout(() => { c2 = 0; }, 700);
                    }

                    if (s4 !== value.runner1LaySize1) {
                        runner1LaySize1[i] = (value.runner1LaySize1);
                        s4 = value.runner1LaySize1;
                    }

                    if (s5 !== value.runner2BackRate1) {
                        runner2BackRate1[i] = (value.runner2BackRate1);
                        s5 = value.runner2BackRate1;
                        c3 = 1;
                        setTimeout(() => { c3 = 0; }, 700);
                    }

                    if (s6 !== value.runner2BackSize1) {
                        runner2BackSize1[i] = (value.runner2BackSize1);
                        s6 = value.runner2BackSize1;
                    }

                    if (s7 !== value.runner2LayRate1) {
                        runner2LayRate1[i] = (value.runner2LayRate1);
                        s7 = value.runner2LayRate1;
                        c4 = 1;
                        setTimeout(() => { c4 = 0; }, 700);
                    }

                    if (s8 !== value.runner2LaySize1) {
                        runner2LaySize1[i] = (value.runner2LaySize1);
                        s8 = value.runner2LaySize1;

                    }

                    if (s9 !== value.tieBackRate1) {
                        tieBackRate1[i] = (value.tieBackRate1);
                        s9 = value.tieBackRate1;
                        c5 = 1;
                        setTimeout(() => { c5 = 0; }, 700);
                    }
                    if (s10 !== value.tieBackSize1) {
                        tieBackSize1[i] = (value.tieBackSize1);
                        s10 = value.tieBackSize1;
                    }

                    if (s11 !== value.tieLayRate1) {
                        tieLayRate1[i] = (value.tieLayRate1);
                        s11 = value.tieLayRate1;
                        c6 = 1;
                        setTimeout(() => { c6 = 0; }, 700);
                    }

                    if (s12 !== value.tieLaySize1) {
                        tieLaySize1[i] = (value.tieLaySize1);
                        s12 = value.tieLaySize1;
                    }

                }


                if (value[0] && value[0].messageType === 'session_rate') {
                    let temp = value;
                    if (marketData.rateMode == '2') {
                        temp.sort(function (a, b) {

                            return moment(a.selectionId) - moment(b.selectionId);
                        });
                    }
                    setfancyData(temp);
                }

                if (value.messageType === 'bookmaker_match_rate') {

                    let temp = { ...bookData };

                    temp.gameStatus = value.status;
                    temp.runner1Status = value.runner1Status;
                    temp.runner2Status = value.runner2Status;
                    temp.runner3Status = value.runner3Status;
                    temp.runner1BackRate1 = value.runner1BackRate1;
                    temp.runner1LayRate1 = value.runner1LayRate1;
                    temp.runner2BackRate1 = value.runner2BackRate1;
                    temp.runner2LayRate1 = value.runner2LayRate1;
                    temp.tieBackRate1 = value.tieBackRate1;
                    temp.tieLayRate1 = value.tieLayRate1;

                    //console.log('book1',value.tieBackRate1,value.tieBackRate1);
                    setbookData(temp);

                }

                if (value.messageType === 'match_score') {
                    let temp = value;
                    setScoreData(temp);
                }


            })
        }

        return () => {
            socket.removeAllListeners(list);
            setrunner1BackRate1([]); setrunner2BackRate1([]);
            setrunner1LayRate1([]); setrunner2LayRate1([]);
            setrunner1BackSize1([]); setrunner2BackSize1([]);
            setrunner1LaySize1([]); setrunner2LaySize1([]);
            setfancyData([]);
        }



    }, [])

    const openfulllmarket = (id, eid, mid, etype) => {
        if (etype == 4) setopenmatchtype("Fairfullmarketcric");
        if (etype == 1) setopenmatchtype("Fairmarket");
        if (etype == 3) setopenmatchtype("Fairfullmarketten");



    }





    return (
        <React.Fragment>

            {refresh === true &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>}
            <div>
                <div id="mobmaincontent" className="mob-main-content" >
                    <h3 id="mobmultiMarketHeader"></h3>



                    {props.isLoggedIn == true ?


                        <React.Fragment>
                            <div >

                                {(match.length === 0) && refresh === false &&

                                    <div id="noMultiMarkets" className="no-data"  >
                                        <h3>There are currently no followed multi markets.</h3>
                                        <p>Please add some markets from events.</p>
                                    </div>
                                }

                                {match.map(function (item, id) {
                                    var matchtype, ctr = 0;
                                    var fullmarketname;
                                    if (item.sportId == 4)
                                        matchtype = "Cricket";
                                    if (item.sportId == 2)
                                        matchtype = "Tennis";
                                    if (item.sportId == 1)
                                        matchtype = "Soccer";
                                    // if(item.sportId==4) fullmarketname="Fairfullmarketcric";
                                    // if(item.sportId==1) fullmarketname="Fairmarket";
                                    // if(item.sportId==2) fullmarketname="Fairfullmarketten";




                                    // console.log("sport:-", item)
                                    return (

                                        <div key={id} id="multiMarketsTemplate" >
                                            {showConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={mOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2} closeConfirmBet={closeConfirmBet} marketBet={marketBet} />}
                                            <div className="game-wrap multi">
                                                <h4 id="gameInfo" className="game-info">{matchtype}
                                                    <ul id="infoIcon" className="info-icon">
                                                        {moment() >= moment(item.marketStartTime) &&
                                                            <li id="inPlayIcon" ><img className="info-inplay" src={Cup} alt="gif" />In-Play</li>
                                                        }


                                                    </ul>
                                                </h4>

                                                <table id="gameTeam" className="game-team">
                                                    <tbody   >
                                                        <tr   >
                                                            <th>
                                                                <a id="multiMarketPin" className="pin-on" onClick={() => Multimarketpin(id, item.eventId)} title="Add to Multi Markets">.</a>
                                                                <h4 id="eventName" style={{ color: 'white' }}>{item.eventName}</h4>
                                                            </th>
                                                            <td className="team-multi_Go">
                                                                <Link to={`/exchange/play/fullmarket/4/${item.eventId}/${item.marketId}`} id="eventName" onClick={() => { props.changeState(true); }}>
                                                                    <a id="goToFullMarket" className="multi-Go">.</a></Link>
                                                            </td>
                                                            <td className="team-refresh"><a id="refresh" className="refresh" onClick={() => refershLoader()} >.</a></td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>

                                            <div id="marketBetsWrap" className="bets-wrap">
                                                <dl id="betsHead" className="bets-selections-head" >
                                                    <dt>
                                                        <a class="a-depth" id="marketDepthBtn" href="#">Markets Depth</a>
                                                        <p id="marketName" style={{ paddingTop: "15px", paddingLeft: '3px', fontSize: '13px' }}>{item.marketName}</p>
                                                    </dt>
                                                    <dd className="mode-land"></dd>
                                                    <dd className="mode-land"></dd>
                                                    <dd   >Back</dd>
                                                    <dd   >Lay</dd>
                                                    <dd className="mode-land"></dd>
                                                    <dd className="mode-land"></dd>
                                                </dl>
                                                {item.matchStatus == 1 && <React.Fragment>

                                                    <dl id="selection_2954260" class="bets-selections" style={{ display: 'flex' }}>
                                                        <dt   >
                                                            <h4 id="runnerName">{item.runnerName1}</h4>
                                                            <ul id="winLoss">
                                                                {click === id && <>
                                                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamA > 0 ? "win" : "lose"}`} >{pnlMarket.teamA > 0 ? pnlMarket.teamA : '(' + Math.abs(pnlMarket.teamA) + ')'}</li>}

                                                                    {click && defaultStake != ' ' && defaultStake != '' &&
                                                                        <li id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`}>{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</li>}
                                                                </>}

                                                            </ul>
                                                        </dt>


                                                        <dd id="back_1" onClick={() => { if (runner1BackRate1[id] != ' ' && runner1BackRate1[id] > 0) changeMatchodds(id, 1, 1, runner1BackRate1[id]); }}>
                                                            <a className={`back-1 ${(selectMatchBack === 1) ? "select" : "null"} ${(c1 === 1) ? "null" : "null"}`} fullmarketodds="2.22">{runner1BackRate1[id]}<span>{runner1BackSize1[id]}</span></a>
                                                        </dd>
                                                        <dd id="lay_1" onClick={() => { if (runner1LayRate1[id] != ' ' && runner1LayRate1[id] > 0) changeMatchodds(id, 2, 1, runner1LayRate1) }}>
                                                            <a className={`lay-1 ${(selectMatchBack === 2) ? "select" : "null"} ${(c2 === 1) ? "null" : "null"}`} fullmarketodds="2.24">{runner1LayRate1[id]}<span>{runner1LaySize1[id]}</span></a>
                                                        </dd>

                                                    </dl>
                                                    {click === id &&
                                                        <div id="betBoardTemplate" className={`bet_slipbar-content ${(selectMatchBack === 1) ? "back" : "lay"}`}>


                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                                    <div id="inputOdds" class="input-num">
                                                                        <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                                        <span id="odds" class="typed">{mOdds}</span>
                                                                        <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                                    </div>
                                                                </li>
                                                                <li   >
                                                                    <p class="dynamic-min-bet">Min Bet: {parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul id="stakePopupList" class="coin-list">
                                                                <li   ><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                                <li   ><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                                <li   ><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                                <li   ><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                                <li   ><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                                <li   ><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                            </ul>

                                                            <div id="keyboard" class="keyboard-wrap">
                                                                <ul id="numPad" class="btn-tel">
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                                    <li   ><a     >.</a></li>
                                                                </ul>
                                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                            </div>

                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                                    </a>
                                                                </li>
                                                                <li   >
                                                                    <a id="placeBet" class="btn-send" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }}>Place Bet
                                                                    </a>
                                                                </li>
                                                            </ul>



                                                        </div>}
                                                    <dl id="selection_17864660" class="bets-selections" style={{ display: 'flex' }}>
                                                        <dt>




                                                            <h4 id="runnerName">{item.runnerName2}</h4>
                                                            <ul id="winLoss">
                                                                {click === id && <>
                                                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</li>}

                                                                    {(click) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</li>}
                                                                </>}


                                                            </ul>
                                                        </dt>



                                                        <dd id="back_1" onClick={() => { if (runner2BackRate1[id] != ' ' && runner2BackRate1[id] > 0) changeMatchodds(id, 3, 2, runner2BackRate1[id]) }}>
                                                            <a className={`back-1 ${(selectMatchBack === 3) ? "select" : "null"} ${(c3 === 1) ? "null" : "null"}`} fullmarketodds="1.81">{runner2BackRate1[id]}<span>{runner2BackSize1[id]}</span></a>
                                                        </dd>
                                                        <dd id="lay_1" onClick={() => { if (runner2LayRate1[id] != ' ' && runner2LayRate1[id] > 0) changeMatchodds(id, 4, 2, runner2LayRate1[id]) }}>
                                                            <a className={`lay-1 ${(selectMatchBack === 4) ? "select" : "null"} ${(c3 === 1) ? "null" : "null"}`} fullmarketodds="1.82">{runner2LayRate1[id]}<span>{runner2LaySize1[id]}</span></a>
                                                        </dd>

                                                    </dl>
                                                    {click === true &&

                                                        <div id="betBoardTemplate" className={`bet_slipbar-content ${(selectMatchBack === 3) ? "back" : "lay"}`}>


                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                                    <div id="inputOdds" class="input-num">
                                                                        <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                                        <span id="odds" class="typed">{mOdds}</span>
                                                                        <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p class="dynamic-min-bet">Min Bet: {parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul id="stakePopupList" class="coin-list">
                                                                <li   ><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                                <li   ><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                                <li   ><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                                <li   ><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                                <li   ><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                                <li   ><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                            </ul>

                                                            <div id="keyboard" class="keyboard-wrap">
                                                                <ul id="numPad" class="btn-tel">
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                                    <li   ><a    >.</a></li>
                                                                </ul>
                                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                            </div>

                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a id="placeBet" class="btn-send" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }}>Place Bet
                                                                    </a>
                                                                </li>
                                                            </ul>


                                                        </div>}

                                                    {/* The Draw component */}


                                                    {item.runnerId3 && item.runnerId3 != ' ' &&
                                                        <dl id="selection_17864660" class="bets-selections" style={{ display: 'flex' }}>
                                                            <dt   >
                                                                <h4 id="runnerName">The Draw</h4>
                                                                <ul id="winLoss">
                                                                    {click === id && <>
                                                                        {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.tie > 0 ? "win" : "lose"}`} >{pnlMarket.tie > 0 ? pnlMarket.tie : '(' + Math.abs(pnlMarket.tie) + ')'}</li>}

                                                                        {(click) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.tie > 0 ? pnlMarket2.tie : '(' + Math.abs(pnlMarket2.tie) + ')'}</li>}
                                                                    </>}

                                                                </ul>
                                                            </dt>



                                                            <dd id="back_1" onClick={() => { if (tieBackRate1[id] != ' ' && tieBackRate1[id] > 0) changeMatchodds(id, 5, 3, tieBackRate1[id]) }}>
                                                                <a className={`back-1 ${(selectMatchBack === 5) ? "select" : "null"} ${(c5 === 1) ? "null" : "null"}`} fullmarketodds="1.81">{tieBackRate1[id]}<span>{tieBackSize1[id]}</span></a>
                                                            </dd>
                                                            <dd id="lay_1" onClick={() => { if (tieLayRate1[id] != ' ' && tieLayRate1[id] > 0) changeMatchodds(id, 6, 3, tieLayRate1[id]) }}>
                                                                <a className={`lay-1 ${(selectMatchBack === 6
                                                                ) ? "select" : "null"} ${(c6 === 1) ? "null" : "null"}`} fullmarketodds="1.82">{tieLayRate1[id]}<span>{tieLaySize1[id]}</span></a>
                                                            </dd>

                                                        </dl>}

                                                    {click === true &&

                                                        <div id="betBoardTemplate" className={`bet_slipbar-content ${(selectMatchBack === 5) ? "back" : "lay"}`}>
                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                                    <div id="inputOdds" class="input-num">
                                                                        <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                                        <span id="odds" class="typed">{mOdds}</span>
                                                                        <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <p class="dynamic-min-bet">Min Bet:{parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <ul id="stakePopupList" class="coin-list">
                                                                <li   ><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                                <li   ><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                                <li   ><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                                <li   ><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                                <li   ><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                                <li   ><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                            </ul>

                                                            <div id="keyboard" class="keyboard-wrap">
                                                                <ul id="numPad" class="btn-tel">
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                                    <li   ><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                                    <li   ><a     >.</a></li>
                                                                </ul>
                                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                            </div>

                                                            <ul class="btn-list">
                                                                <li   >
                                                                    <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                                    </a>
                                                                </li>
                                                                <li   >
                                                                    <a id="placeBet" class="btn-send" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }}>Place Bet
                                                                    </a>
                                                                </li>
                                                            </ul>



                                                        </div>}
                                                </React.Fragment>}






                                            </div>
                                        </div>
                                    );
                                })}</div>
                        </React.Fragment> :
                        <div id="noMultiMarkets" className="no-data"  >
                            <h3>There are currently no followed multi markets.</h3>
                            <p>Please add some markets from events.</p>
                        </div>
                    }




                </div>
                <Footer />
            </div>
        </React.Fragment>

    )
}

