import React, { useState } from 'react';
import ReactQueryProvider from './serives/react-query/provider';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import AppWrapper from './component/app-wrapper/app-wrapper';
// import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css';


import Combine from './mobilecomponent/Combine';
import CasinoModal from './mobilecomponent/CasinoModal';
import bgImage from './images/bajie365/side_bg.jpg';
import MyComponent from 'react-mobile-wrapper'
import PullToRefresh from 'react-simple-pull-to-refresh';


const loc = window.location.href;
const param = loc.split('/');

// require('./mobilecomponent/all.css');

export default function App() {

  return (
    <ReactQueryProvider>
      <AppWrapper>
        <Router>
          <Switch>
            <Route exact path='/' component={Combine}></Route>
            <Route exact path='/:eventType' component={Combine}></Route>
            <Route exact path='/:eventType/:subType/:belowType' component={Combine}></Route>
            <Route exact path='/:subType/:belowType/fullmarket/:eventT/:eventId/:marketId' component={Combine}></Route>
            <Route path='/game-play/casino' component={CasinoModal} />
          </Switch>
        </Router>
      </AppWrapper>
    </ReactQueryProvider>
  )
}
