import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import livecasino from '../images/casino/banner_casino-half.webp'
import roulletee from '../images/casino/ezugi.webp'
import venuscasino from '../images/casino/venus.webp'
import Colorgame from '../images/casino/colorgame.jpeg'
import card32 from '../images/kv/32card-half.png'
import teenipatti from '../images/casino/teenpatti20.webp';
import cockfight from '../images/casino/cockfight.webp'
import updown from '../images/casino/7up7down.jpeg'
import Evolution from '../images/casino/banner_evo-half.webp'
import sportse from '../images/casino/sportse.webp'
import anderbahar from '../images/casino/andarbahar.webp'
import CoinToss from '../images/kv/CoinToss.png'
import blackjack from '../images/casino/Blackjack.webp'
import numbermatka from '../images/kv/numberMatka.png'
import Sports from '../images/Banner/bannersports.webp'
import cardmatka from '../images/kv/cardMatka.png'
import mineswiper from '../images/casino/mineswiper.jpeg'
// import mojosseven from '../images/casino/venus-01.png'
import { Link } from 'react-router-dom';
import Support from './Support';
import sibco from '../images/casino/sicbokm.jpeg'
import SABA from '../images/casino/saba-sport.webp'
import KingMakers from '../images/casino/lobby_kingmakers.ad101fd6.webp'
import ludogame from '../images/casino/LUDO.webp'
import TeenpattiRoyal from '../images/casino/aesexy.webp'
import roulette from '../images/casino/europianroullete.webp'
import cardhilo from '../images/casino/cardhillo.webp'
import NumberKing from '../images/casino/numberking.webp'
import JilI from '../images/casino/banner_JILI-full.webp'
import BigSmall from '../images/casino/big-small.webp'
import Teenpattijoker from '../images/casino/teenpatti.jpeg'
import updownseven from '../images/casino/7updown.webp'
import DragerTiger from '../images/casino/dragon-tiger.jpeg'
import CallBreak from '../images/casino/callbreak.webp'
import SicboJili from '../images/casino/sicbo.webp'
import BaccaratJili from '../images/casino/baccaret.webp'
import horserace from '../images/Banner/horse-race.webp'
import pragmatics from '../images/casino/pp.jpeg'

import Cookies from 'universal-cookie';
import { getCasinoObj } from '../casino-utils';
import queryString from 'query-string';
import { requirePropFactory } from "@material-ui/core";

const cookies = new Cookies();
export default function Home(props) {

    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [countBasketBall, setCountBasketBall] = useState(0);
    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [casinoWait, setcasinoWait] = useState(false);

    const history = useHistory();


    function changecount(cri, socc, tenn, basket) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
        setCountBasketBall(basket)
    }


    useEffect(() => {
        axios.all([
            axios.get('https://luckybdt.live/clientapi/cricket'),
            axios.get('https://luckybdt.live/clientapi/soccer'),
            axios.get('https://luckybdt.live/clientapi/tennis'),
            // axios.get('https://luckybdt.live/clientapi/Basketball')
        ])
            .then(axios.spread((obj1, obj2, obj3, obj4) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;
                var count4 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                // obj4.data.map(item => {
                //     item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                //     return item;
                // })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                // obj4.data.map((item) => {
                //     if (moment() >= moment(item.marketStartTime)) {
                //         count4 = count4 + 1;
                //     }
                // });
                setrefresh(false);
                setTimeout(() => { setloading(false); }, 5000);
                changecount(count1, count2, count3);



            }));
    }, [])

    const handleRefresh = () => {
        setloading(true);
    }

    return (
        <React.Fragment>

            {props.casinoWait &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }







            {refresh === true &&
                <div id="poker_loading" class="loading-overlay" style={{ display: 'flex' }}>
                    <div class="loading-wrap">
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }


            <div class="mob-main-content">
                <div class="casinozone">
                    <Link to="/exchange/play/inplay" style={{ gridColumn: 'span 2' }}>
                        <dl class="casino-title">
                            <dt>Sports</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <dl id="onLiveBoard" class="live_events">
                            <dt>
                                <p class="live_icon"><span></span> LIVE</p>
                            </dt>

                            <dd id="onLiveCount_CRICKET"><p>Cricket</p><span id="count">{countCricket}</span></dd>

                            <dd id="onLiveCount_SOCCER"><p>Soccer</p><span id="count">{countSoccer}</span></dd>

                            <dd id="onLiveCount_TENNIS"><p>Tennis</p><span id="count">{countTennis}</span></dd>

                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/1.jpg" alt="" draggable="false" />
                    </Link>






                    <a onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('EVOLUTION'))}`) : props.openLogin()}>
                        <dl class="casino-title">
                            <dt>Teenpatti</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/2.jpg" alt="" draggable="false" />
                    </a>
                    <a className="mob-casino-half"
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('LUDO', 'platform')}/${getCasinoObjVal('LUDO', 'gameType')}/${getCasinoObjVal('LUDO', 'title')}/${getCasinoObjVal('LUDO', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Live Casino'))}`) : props.openLogin()}

                    >
                        <dl className="casino-title">
                            <dt>Andar Bahar</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/4.jpg" alt="" draggable="false" />
                    </a>
                    <a className="mob-casino-half"
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('LUDO', 'platform')}/${getCasinoObjVal('LUDO', 'gameType')}/${getCasinoObjVal('LUDO', 'title')}/${getCasinoObjVal('LUDO', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('VENUS'))}`) : props.openLogin()}

                    >
                        <dl className="casino-title">
                            <dt>Roulette</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/3.jpg" alt="" draggable="false" />
                    </a>

                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('sicbo', 'platform')}/${getCasinoObjVal('sicbo', 'gameType')}/${getCasinoObjVal('sicbo', 'title')}/${getCasinoObjVal('sicbo', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('sicbo'))}`) : props.openLogin()}
                        class="mob-casino-half">
                        <dl class="casino-title">
                            <dt>Cricket</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/5.jpg" alt="" draggable="false" />
                    </a>

                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('E1SPORT', 'platform')}/${getCasinoObjVal('E1SPORT', 'gameType')}/${getCasinoObjVal('E1SPORT', 'title')}/${getCasinoObjVal('E1SPORT', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('E1SPORT'))}`) : props.openLogin()}

                        style={{ gridColumn: 'span 2' }}>
                        <dl className="casino-title">
                            <dt>Live Casino</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/6.jpg" alt="" draggable="false" />
                    </a>






                    {/* lobby_kingmakers.webp */}


                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('7 Up 7 Down', 'platform')}/${getCasinoObjVal('7 Up 7 Down', 'gameType')}/${getCasinoObjVal('7 Up 7 Down', 'title')}/${getCasinoObjVal('7 Up 7 Down', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('7 Up 7 Down'))}`) : props.openLogin()}

                        class="mob-casino-half">
                        <dl class="casino-title">
                            <dt>Sportsbook_</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/sportsbook_.jpeg" alt="" draggable="false" />
                    </a>
                    {/* onClick = {()=>{openEvolution(6511);}} */}
                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('Coin Toss', 'platform')}/${getCasinoObjVal('Coin Toss', 'gameType')}/${getCasinoObjVal('Coin Toss', 'title')}/${getCasinoObjVal('Coin Toss', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Coin Toss'))}`) : props.openLogin()}

                        class="mob-casino-half">
                        <dl class="casino-title">
                            <dt>Sports-Inplay</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/images/sportsbook_inplay.jpeg" alt="" draggable="false" />
                    </a>


                    <a
                        class="mob-casino-half"
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('NumberMatka', 'platform')}/${getCasinoObjVal('NumberMatka', 'gameType')}/${getCasinoObjVal('NumberMatka', 'title')}/${getCasinoObjVal('NumberMatka', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('NumberMatka'))}`) : props.openLogin()}
                        style={{ cursor: "pointer" }}>
                        <dl class="casino-title">
                            <dt>Ezugi</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/homebanners/image-(64).jpg" alt="" draggable="false" />
                    </a>

                    <a
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('CardMatka'))}`) : props.openLogin()}
                        class="mob-casino-half" >
                        <dl class="casino-title">
                            <dt>XPG</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/homebanners/image-(58).jpg" alt="" draggable="false" />
                    </a>

                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('Andar Bahar', 'platform')}/${getCasinoObjVal('Andar Bahar', 'gameType')}/${getCasinoObjVal('Andar Bahar', 'title')}/${getCasinoObjVal('Andar Bahar', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Andar Bahar'))}`) : props.openLogin()}

                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl class="casino-title">
                            <dt>Evolution</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/homebanners/image-(51).jpg" alt="" draggable="false" />
                    </a>

                    {/* onClick = {()=>{openEzugi('poker');}} */}

                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('Blackjack', 'platform')}/${getCasinoObjVal('Blackjack', 'gameType')}/${getCasinoObjVal('Blackjack', 'title')}/${getCasinoObjVal('Blackjack', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Blackjack'))}`) : props.openLogin()}

                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl class="casino-title">
                            <dt>Slots</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src="https://www.playinexch.com/exchange/homebanners/3.jpg" alt="" draggable="false" />
                    </a>

                    {/* onClick={() => props.handleCasinoBal('EZ','LC', 'Ezugi', props.EZBalance)} */}

                    <a class="mob-casino-half">
                        <dl class="casino-title">
                            <dt>Ezugi Casino</dt>
                            <dd><span>Play Now</span></dd>

                        </dl>
                        <img src="https://www.playinexch.com/images/tvbetbanner.jpeg" alt="" draggable="false" />

                    </a>
                    <a className="mob-casino-half"
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('pragmatics play', 'platform')}/${getCasinoObjVal('pragmatics play', 'gameType')}/${getCasinoObjVal('pragmatics play', 'title')}/${getCasinoObjVal('pragmatics play', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('pragmatics play'))}`) : props.openLogin()}

                    >
                        <dl className="casino-title">
                            <dt>Pregmatics Play</dt>
                            <dd><span>Play Now</span></dd>

                        </dl>
                        <img src="https://www.playinexch.com/img/vivo.jpg" alt="" draggable="false" />

                    </a>





                    <a
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/${getCasinoObjVal('HORSE BOOK', 'platform')}/${getCasinoObjVal('HORSE BOOK', 'gameType')}/${getCasinoObjVal('HORSE BOOK', 'title')}/${getCasinoObjVal('HORSE BOOK', 'gameCode')}`) : props.openLogin()}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('HORSE BOOK'))}`) : props.openLogin()}

                        style={{ gridColumn: 'span 2' }}>
                        <dl className="casino-title">
                            <dt>Horse Racing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src={horserace} alt="" draggable="false" />
                    </a>




                </div>

                <Support handlepolicy={props.handlepolicy} />
            </div>

        </React.Fragment>
    )
}
