import React from 'react';
import './app-wrapper.css';

import bgImage from "../../images/bajie365/side_bg.jpg";
import logoImage from "../../images/bajie365/playinexch.png";
import { MobileBox, useMobileSize } from '../../utils/mobile-size/mobile-size';

const AppWrapper = ({children}) => {
    const { isMobileSize, offSet, mobileWidth } = useMobileSize();
    return (
        <div
            style={{
                backgroundImage: `url('${bgImage}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                position: 'fixed',
                height: '100vh',
                width: '100%'
            }}
        >
                    <MobileBox id="mobileMainWrapper" className="main-wrapper" style={ !isMobileSize ? {
                        margin: '2.5vh 0.6rem auto',
                        borderRadius: '0.8rem',
                        overflow: 'hidden',
                        backgroundColor: '#f7f6fb',
                        boxShadow: '0 0 20px 5px rgba(0,0,0,.5)'
                    } : {}}>
                        <div
                            style={{
                                ...(isMobileSize ?  {height: '100vh' } : { height: '207vh' }),
                                overflow: 'auto',
                                padding: '0',
                                position: 'relative',
                                backgroundColor: 'white',
                            }}
                        >
                            {children}
                        </div>
                    </MobileBox>
            {!isMobileSize && (
                <div className="logoBox">
                    <img src={logoImage} style={{width: '100%', height: 'auto'}} alt="logo"/>
                </div>
            )}

        </div>
    )
}

export default AppWrapper;
