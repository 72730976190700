import React from 'react'

const Activitylog = () => {
  return (
    <>
      <div className="table_div" style={{ paddingTop: '10px' }}>
        <div id="table_wrapper" className="dataTables_wrapper no-footer">
          <div className="dataTables_length" id="table_length"><label>Show <select name="table_length" aria-controls="table" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> entries</label></div>
          <div className="dataTables_scroll">
            <div className="dataTables_scrollHead" style={{ overflow: 'hidden', position: 'relative', border: '0px', width: '100%' }}>
              <div className="dataTables_scrollHeadInner" style={{ boxSizing: 'content-box', width: '523px', paddingRight: '0px' }}>
                <table className="table table-hover dataTable no-footer" role="grid" style={{ marginLeft: '0px', width: '523px' }}>
                  <thead className="table_head">
                    <tr role="row">
                      <th className="sorting" tabIndex={0} aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '35px' }} aria-label="Date: activate to sort column ascending">Date</th>
                      <th className="sorting" tabIndex={0} aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '238px' }} aria-label="Ip Address: activate to sort column ascending">Ip Address</th>
                      <th className="sorting" tabIndex={0} aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '67px' }} aria-label="Location: activate to sort column ascending">Location</th>
                      <th className="sorting" tabIndex={0} aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '39px' }} aria-label="Status: activate to sort column ascending">Status</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="dataTables_scrollBody" style={{ position: 'relative', overflow: 'auto', width: '100%' }}>
              <table className="table table-hover dataTable no-footer" id="table" role="grid" style={{ width: '523px' }}>
                <thead className="table_head">
                  <tr role="row" style={{ height: '0px' }}>
                    <th className="sorting" aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '35px', paddingTop: '0px', paddingBottom: '0px', borderTopWidth: '0px', borderBottomWidth: '0px', height: '0px' }}>
                      <div className="dataTables_sizing" style={{ height: '0px', overflow: 'hidden' }}>Date</div>
                    </th>
                    <th className="sorting" aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '238px', paddingTop: '0px', paddingBottom: '0px', borderTopWidth: '0px', borderBottomWidth: '0px', height: '0px' }}>
                      <div className="dataTables_sizing" style={{ height: '0px', overflow: 'hidden' }}>Ip Address</div>
                    </th>
                    <th className="sorting" aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '67px', paddingTop: '0px', paddingBottom: '0px', borderTopWidth: '0px', borderBottomWidth: '0px', height: '0px' }}>
                      <div className="dataTables_sizing" style={{ height: '0px', overflow: 'hidden' }}>Location</div>
                    </th>
                    <th className="sorting" aria-controls="table" rowSpan={1} colSpan={1} style={{ width: '39px', paddingTop: '0px', paddingBottom: '0px', borderTopWidth: '0px', borderBottomWidth: '0px', height: '0px' }}>
                      <div className="dataTables_sizing" style={{ height: '0px', overflow: 'hidden' }}>Status</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="even">
                    <td>
                      <span>2 months ago</span>
                    </td>
                    <td>2402:e280:3d7a:62b:cc0e:e78b:12b2:5316</td>
                    <td>
                      Mumbai, Maharashtra, - 400072 India
                    </td>
                    <td>Success</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="dataTables_paginate paging_simple_numbers" id="table_paginate"><a className="paginate_button previous disabled" aria-controls="table" data-dt-idx={0} tabIndex={-1} id="table_previous">Previous</a><span><a className="paginate_button current" aria-controls="table" data-dt-idx={1} tabIndex={0}>1</a></span>
            <a
              className="paginate_button next disabled" aria-controls="table" data-dt-idx={2} tabIndex={-1} id="table_next">Next</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Activitylog
